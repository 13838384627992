import React from 'react'
import { Box, Text, Flex, Grid, Heading, Styled } from 'theme-ui'
import Layout from "../components/layout"
import Image from "../components/Image"
import Link, {GooglePlay, AppStore, Anchor} from "../components/Link"
import Toggle from "../components/Toggle"
import Slideshow from "../components/Slideshow"
import SEO from "../components/seo"
import {Parallax} from "react-scroll-parallax"


export default () => 
  <Layout>
    <SEO keywords={['design', 'product designer', 'interface designer', 'case study', 'ux case study']} title='KUPIVIP Case Study' description="Creating a coherent user experience across mobile apps of the largest online fashion outlet in Russia, and improving performance on key metrics." project="kupivip" />

    <Heading as="h1" variant="text.h1" sx={{mb: 6}}>KUPIVIP</Heading>
    <Box sx={{maxWidth: '50rem', mb: 8}}>
      <Text variant="h4">Creating a coherent user experience across mobile apps of the largest online fashion outlet in Russia, and improving performance on key metrics.</Text>

      <Flex sx={{ mt: [4, 5], mx: [-2, -3],
            justifyContent: 'space-between',
            flexDirection: ['column', 'row'],'a': { mb: [2, 0], }}}
      >
        <Link href="https://apps.apple.com/ru/app/kupivip-онлайн-магазин/id927992143?l=en" sx={{variant: "links.button", flexBasis: [null, 'calc(100%/3 - 4px)', 'auto', 'calc(100%/3 - 4px)'], 'div': {display: ['block', null, 'none', 'block']}}} ><AppStore sx={{my: -1}}/><span>App Store</span></Link>
        <Link href="https://play.google.com/store/apps/details?id=ru.kupivip.android&hl=en" sx={{variant: "links.button", flexBasis: [null, 'calc(100%/3 - 4px)', 'auto', 'calc(100%/3 - 4px)'], 'div': {display: ['block', null, 'none', 'block']}}} ><GooglePlay sx={{my: -1}}/><span>Google Play</span></Link>
        <Link href="https://kupivip.ru" sx={{variant: "links.button", flexBasis: [null, 'calc(100%/3 - 4px)'], flexGrow: [0, null, 1, 0]}} >
          <Anchor sx={{my: -1}} />
          <span>Website</span>
        </Link>
      </Flex>
    </Box>

    <Box variant='boxes.gaplessImage' sx={{mb: 7, px: 8}}>
      <Parallax y={[30, -70]} styleOuter={{maxHeight: "52.612vw"}}>
        <Image src="kupivip/hero-parallax.png" alt='App' />
      </Parallax>
    </Box>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="①" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        An Overview, Goals and Challenges
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 4, pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
            KUPIVIP originated as a web-only e-commerce fashion website. Sometime in the mid-2010s, going through the motions, both iOS and Android apps were developed by different outsourced teams. That led to an inconsistent user experience: our apps looked and behaved differently across the platforms.
          </Text>
        </Box>
        <Box sx={{mt: 5, mb: 8, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
            <Box sx={{maxWidth: "700px"}}>
              <Text variant="h3" as="p">
                Goals and Challenges
              </Text>

              <Styled.ul>
                <Styled.li>Refine and improve our user experience on mobile, to not only meet the competitive baseline, but to exceed it;</Styled.li>
                <Styled.li>Come up to a unified design solutions that would work great on both iOS and Android;</Styled.li>
                <Styled.li>Create a library of reusable components to speed-up the development process and encourage early experimentation and speedy iteration;</Styled.li>
                <Styled.li>Keep an eye on core metrics, such as conversion rate, average order value, cart abandonment rate, refund rate, and return rate, and constantly find ways to rocket them;</Styled.li>
                <Styled.li>Work within constraints of our server architecture, business model, and local laws.</Styled.li>
              </Styled.ul>
            </Box>
          </Grid>
        </Box>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="② " sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        Mobile Design System
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 5, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
            <Box>
              <Text as="p">
                Shortly before I joined the company, KUPIVIP has hired an in-house mobile development team with a very talented designer. He initially started shaping up a new visual style for our mobile apps, but soon after he quit the job, which ended up with me having to work with dozens of screens and concepts uploaded to Zeplin on my own.
              </Text>
              <Text as="p">
                On my 30-60-90 plan I set the goal to move all the pixels from Zeplin into Sketch components. We’ve created a mobile design system to help our designers, PMs, and developers communicate with each other in order to build consistent high-quality solutions at a faster pace. Our design system contains design tokens like typography, spacings, and colour scheme, and also libraries of both shared, and specific to iOS and Android components.
              </Text>
            </Box>
            <Box>
              <Text as="p">
                Creating these components was not a simple job of transferring the pixels from Zeplin, since the same visual elements could vary from screen to screen. Because of my passion for the product consistency, I had to review every pixel of the uploaded layouts to come up with a structure of components for the design system.
              </Text>
              <Text as="p">
                Besides this pattern library, the final design system also included our design principles, tone of voice, and best practices that later, with some additions and amendments, were also applied to our website.
              </Text>
            </Box>
          </Grid>
        </Box>
        <Box sx={{bg: "subtleBg", borderRadius: 3, py: [6, 7], mt: 7, mb: 8}}>
          <Image src="kupivip/components.png" alt='Some of the components' height="40vw" sx={{mx: "auto"}}/>
        </Box>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="③" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>Streamlined Navigation</Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 5, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
          We reworked our navigation on both platforms. More dramatic changes came to the Android app as we replaced the navigation drawer with a bottom navigation, in order to provide a better discoverability of the app’s sections for the first time users. It wasn’t a norm back then, as it was utilised only in Google Photos, but this change has performed well on a hall test and then on production.
          </Text>
          <Box sx={{mb: 6}}>
            <Text variant="h3" as="h3" sx={{maxWidth: "800px", mb: 6, }}>
              Making it a thumb-friendly
            </Text>
          </Box>
        </Box>
        <Box sx={{mb: 8}}>
          <Box sx={{bg: "subtleBg", borderRadius: 3, py: [6, 7], mt: 7, mb: 4}}>
            <Image src="kupivip/new-nav.png" alt='New Navigation' height="40vw" sx={{mx: "auto"}}/>
          </Box>
          <Text as="p" sx={{maxWidth: "800px", pl: 'calc((1.025vw + 18.72px) * 1.1)', mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          On Android, to navigate through the hierarchy of available categories and subcategories users had to scroll the drawer (left), and the cart was located in the top right corner so it wasn’t visible when the drawer was open. We replaced the navigation drawer with a bottom navigation (center). iOS allows up to five items in the tab bar so we’ve made a decision to add the Wishlist to the navigation (right).
          </Text>
        </Box>

        <Box sx={{mt: 5, mb: 6, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Text variant="h3" as="h3">Personalised Home Screen</Text>
        </Box>
        <Box sx={{mb: 8}}>
          <Box sx={{bg: "subtleBg", borderRadius: 3, py: [6, 7], mt: 7, mb: 4}}>
            <Image src="kupivip/main-ios.png" alt='New home screen' height="40vw" sx={{mx: "auto"}}/>
          </Box>
          <Text as="p" sx={{maxWidth: "800px", pl: 'calc((1.025vw + 18.72px) * 1.1)', mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          We got rid of the list of offers on the home screen and made it a hub which allows users to navigate wherever they want, and highlights the items they are currently interested in or might find relevant in future.
          </Text>
        </Box>

        <Box sx={{mt: 5, mb: 6, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Text variant="h3" as="h3">
            Catalog and Filters
          </Text>
        </Box>
        <Box sx={{mb: 8}}>
          <Box sx={{bg: "subtleBg", borderRadius: 3, py: [6, 7], mt: 7, mb: 4}}>
            <Image src="kupivip/catalog-ios.png" alt='New Catalog' height="40vw" sx={{mx: "auto"}}/>
          </Box>
          <Box sx={{maxWidth: "800px", pl: 'calc((1.025vw + 18.72px) * 1.1)', mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
            <Text variant="caption" sx={{mb: 1}}>
              Categories got subcategory selectors to allow users to quickly narrow down the products within a category. We introduced the filter bar with the most used filters and placed it at the bottom.
            </Text>
            <Text variant="caption">
              We have improved our filtering experience: positioned important filters on top, added category-specific filters, allowed users to narrow down the results to the designers they like in a single tap.
            </Text>

          </Box>
        </Box>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4, mx: [-4, -6, -8]}}>
      <Toggle.Heading index="④" sx={{variant: 'text.h2', mx: [4,6,8], cursor: 'pointer', '&:hover': {opacity: 0.3}}}>Cart and Checkout</Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 5, mx: [4,6,8], mb: 6, pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
            Unlike the most majority of e-commerce services (at least in Russia), we had a challenge of supporting multiple shopping carts: one cart per one of three of our partners. We didn’t position ourselves as a marketplace, so we couldn’t communicate the real reasoning behind that split. We were also constrained to initiate checkout for each cart by the finance and accounting nuances.
          </Text>
        </Box>

        <Box sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)', mb: 6, mx: [4,6,8], maxWidth: "700px"}}>
          <Text variant="h3" as="h3" sx={{mb: 3}}>
            New Cart
          </Text>
          <Text>
            When customer adds products of our overseas partners, the shopping cart splits as per the partners. It wasn’t a huge problem since 95% of all orders were delivered from our own warehouse and ended up in a single cart. In the remaining cases it was a disaster that had to be addressed.
          </Text>
        </Box>

        <Grid sx={{gridTemplateColumns: "auto auto auto", columnGap: [4, null, 8], mx: [4,6,8], bg: "subtleBg", borderRadius: 3, py: [6, 7], mt: 4, px: "calc((1.025vw + 18.72px) * 1.1)", alignItems: "center", mb: 8}}>
          <Text variant='caption' sx={{gridColumn: ["1/-1", "span 1"]}}>
            <b>Before</b> the change, the cart screen had a segmented control with all the sub-carts being a segment. Some users didn’t understand why there were multiple carts, some didn’t notice them at all and simply abandoned them.
          </Text>
          <Image
            src="kupivip/cart-ios.png"
            alt='Previous and current carts side by side.'
            height="40vw"
            sx={{
              gridColumn: ["1/-1", "span 1"],
              mx: "auto",
            }} />
          <Text variant='caption' sx={{gridColumn: ["1/-1", "span 1"]}}>
            <b>After</b> some conciderations we presented all sub-carts (with upfront estimate of the total cost) in a stack with an explanation of why we split up the items into several orders. This change raised awareness of multiple carts and reduced the cart abandonment rate.
          </Text>
        </Grid>

        <Box sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)', mx: [4,6,8], mb: 7}}>
          <Text variant="h3" as="h3" sx={{mb: 3}}>New Checkout</Text>
          <Box sx={{maxWidth: [null, "44rem", null, "76rem"]}}>
            <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
              <Box>
                <Text as="p">
                  Our checkout screen had the largest dropout rate of all steps of the funnel. We’ve run a numerous amount of quantitative and qualitative tests that shown that:
                </Text>
                <Styled.ul>
                  <Styled.li>A shopping card combined with checkout on a single screen performs better;</Styled.li>
                  <Styled.li>Many users use cart as a wish-list;</Styled.li>
                  <Styled.li>In cases when user’s cart total was less than the minimum order value, they would rather abandon the order than add more items. Especially for sub-carts with items from our partners;</Styled.li>
                  <Styled.li>It’s hard to make an order for another person, e.g. your relatives.</Styled.li>
                </Styled.ul>
              </Box>
              <Box>
                <Text as="p">
                  We had tested a multi-step checkout but it didn’t perform well. Instead, we continued to use a one-screen checkout with all the elements but built it from the ground up. We took into account all possible scenarios and made the checkout process linear and easy to complete, whether you are a first-time buyer or a signed-in user; whether the items value is within the limit of duty-free import or exceeds it; whether the order is below the minimum order value or not; and so on.
                </Text>
              </Box>
            </Grid>
          </Box>
        </Box>
        <Box sx={{mb: 8}}>
          <Slideshow altStyle={{maxWidth: "800px", pl: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
            <Box sx={{variant: "boxes.slideWrapper"}} altText="New checkout process is linear. Since it’s combined with a cart, at first we show all the items a user’s added to the cart.">
              <Box sx={{variant: "boxes.slideL"}}>
              <Image src="kupivip/checkout-1.png"  alt='' height="40vw" />
              </Box>
            </Box>
            <Box sx={{variant: "boxes.slideWrapper"}} altText="If the minimum order value’s not exceeded, we prompt users to filter out the catalog with the supplier’s items and add more items to the cart.">
              <Box sx={{variant: "boxes.slideL"}}>
              <Image src="kupivip/checkout-5.png"  alt='' height="40vw" />
              </Box>
            </Box>
            <Box sx={{variant: "boxes.slideWrapper"}} altText="Then we ask the user to add their contacts, shipping destination (it’s prepopulated from an IP address or GPS coords), and pick a delivery method.">
              <Box sx={{variant: "boxes.slideL"}}>
              <Image src="kupivip/checkout-2.png"  alt='' height="40vw" />
              </Box>
            </Box>
            <Box sx={{variant: "boxes.slideWrapper"}} altText="Delivery methods are based on the user’s location. For each delivery method, even those that are not selected, we show an estimated delivery date and the cost.">
              <Box sx={{variant: "boxes.slideL"}}>
              <Image src="kupivip/delivery-options.png"  alt='' height="40vw" />
              </Box>
            </Box>
            <Box sx={{variant: "boxes.slideWrapper"}} altText="In case the user wants to make an order for another person, it’s now a signle tap effort.">
              <Box sx={{variant: "boxes.slideL"}}>
              <Image src="kupivip/checkout-6.png"  alt='' height="40vw" />
              </Box>
            </Box>
            <Box sx={{variant: "boxes.slideWrapper"}} altText="Then we prompt to select a payment method and apply a coupon (or add a new one).">
              <Box sx={{variant: "boxes.slideL"}}>
              <Image src="kupivip/checkout-3.png"  alt='' height="40vw" />
              </Box>
            </Box>
            <Box sx={{variant: "boxes.slideWrapper"}} altText="Usually, e-commerce websites allow users to enter a code at checkout. At KUPIVIP user may have several coupons (stored in their profile) so we show them in a modal. They can add a new one right there.">
              <Box sx={{variant: "boxes.slideL"}}>
              <Image src="kupivip/checkout-4.png"  alt='' height="40vw" />
              </Box>
            </Box>
          </Slideshow>
          <Box sx={{bg: "subtleBg", borderRadius: 3, py: [6, 7], mt: 7, mx: [4, 6, 8]}}>
            <Image src="kupivip/pickup-ios.png" alt='Search screen' height="40vw" sx={{mx: "auto"}}/>
          </Box>
          <Text as="p" sx={{maxWidth: "800px", px: [8, 9, 12], mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
            Initially, user had an option to deliver their order to one of our five stores which were shown in a(the) list. Later we have partnered with several parcel locker providers and expanded our pickup locations to dozens or even hundreds in each city. To show all the locations we’ve created a map view.
          </Text>
        </Box>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="⑤" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        Size Widget
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 4, pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
          We had a great opportunity to partner with Tardis (now Sizolution), a new startup that measures garment properties and processes 80 customer’s body measurements from a simple questionnaire or a full body selfie. By matching that information together it helps customers select the right size.
          </Text>
          <Text as="p" sx={{maxWidth: "700px", mb: 6}}>
          At the time of the integration, Tardis had nothing but the technology, so I had to create the userflow from scratch. We’ve designed and tested several ideas for the questionnaire to gather user’s body parameters, and decided to use the one with image answer options‎. After introducing the widget, we got 20% returns decrease and 30% revenue increase.
          </Text>
        </Box>
        <Box sx={{my: 7}}>
          <Box sx={{bg: "subtleBg", borderRadius: 3, py: [6, 7]}}>
            <Image src="kupivip/tardis-onboarding.png" alt='Onboarding for the size widget' height="40vw" sx={{mx: "auto"}}/>
          </Box>
          <Text as="p" sx={{maxWidth: "800px", pl: 'calc((1.025vw + 18.72px) * 1.1)', mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          Product screen allows users to calculate their size. To do that, user has to enter their height and weight, and then either upload a full body selfie, or pick their body type, hips and tummy shape from using the images I’ve designed. We store that information later so they only need to enter that once.
          </Text>
        </Box>
        <Grid sx={{gridTemplateColumns: "auto auto auto", columnGap: [4, null, 8], bg: "subtleBg", borderRadius: 3, py: [6, 7], mt: 4, px: "calc((1.025vw + 18.72px) * 1.1)", alignItems: "center", mb: 8}}>
          <Text variant='caption' sx={{gridColumn: ["1/-1", "span 1"]}}>
            When user measurements are added, the product screen suggests a recommended size. By clicking on the “Your best fit” button in the size selector user gets a modal sheet with all the garment’s parameters.
          </Text>
          <Image
            src="kupivip/tardis-active.png"
            height="40vw"
            sx={{
              gridColumn: ["1/-1", "span 1"],
              mx: "auto",
            }} />
          <Text variant='caption' sx={{gridColumn: ["1/-1", "span 1"]}}>
            In that sheet we show all key parameters of the garment and tell user how this particular size would fit them. On this screen user is also able to change their information by clicking on the “Change your measurements” button.
          </Text>
        </Grid>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="⑥" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        Profile and Orders
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 4, pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
          All-new user profile provides key information about bonuses and personal discounts, and allows to get in touch with customer support in a tap. But most importantly, we’ve provided holistic overview of what’s happening with the order.
          </Text>
        </Box>
        <Text variant="h3" as="h3" sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          Profile
        </Text>
        <Box sx={{my: 7}}>
          <Box sx={{bg: "subtleBg", borderRadius: 3, py: [6, 7]}}>
            <Image src="kupivip/profile.png" alt='Profile' height="40vw" sx={{mx: "auto"}}/>
          </Box>
          <Text as="p" sx={{maxWidth: "800px", pl: 'calc((1.025vw + 18.72px) * 1.1)', mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          All-new user profile allows users to access key information about their orders, bonuses, personal discounts, and also contact our customer support team.
          </Text>
        </Box>
        <Text variant="h3" as="h3" sx={{pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          Orders
        </Text>
        <Box sx={{my: 7}}>
          <Box sx={{bg: "subtleBg", borderRadius: 3, py: [6, 7]}}>
            <Image src="kupivip/orders.png" alt='Orders' height="40vw" sx={{mx: "auto"}}/>
          </Box>
          <Text as="p" sx={{maxWidth: "800px", pl: 'calc((1.025vw + 18.72px) * 1.1)', mt: 4, fontFamily: 'monospace', fontSize: '11px'}}>
          My Orders section allows users to view and manage their orders; we worked with our logistic team to let users update their contacts and address for the orders which haven’t been dispatched yet.
          </Text>
        </Box>
      </Toggle.Content>
    </Toggle>

    <Box sx={{my: 4}}>
      <Text sx={{variant: 'text.h2', mb: 0}}>⑦ Results</Text>
      <Box sx={{mt: 5, mb: 6, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
        <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
          <Box as="ul" sx={{variant: 'styles.ul', mt: 0}}>
            <Styled.li>Read all the Baymard studies. Proved some of them wrong;</Styled.li>
            <Styled.li>In a year we’ve completely updated the design of the app on both platforms;</Styled.li>
            <Styled.li>Conducted dozens of user interviews;</Styled.li>
            <Styled.li>Streamlined our internal processes and improved communcation with developers. Emptied backlog;</Styled.li>
            <Styled.li>Onboarded two new designers;</Styled.li>
            <Styled.li>Created a new direction for the catalog photo shoots;</Styled.li>
            <Styled.li>Evolved our branding: introduced a new typeface across the platforms (thank you, Gayane Bagdasaryan!), created new guidelines for banners and graphic materials;</Styled.li>
            <Styled.li>Worked closely with the hired agency on a website redesign.</Styled.li>
          </Box>
          <Box>
            <Text as="p">
              We’ve also created the app extensions for Apple Watch and iMessage, got featured on the App Store several times, and increased the average app rating from 3.6 to 4.7 stars. <span role="img" aria-label="Whoa!">🚀</span>
            </Text>
            <Text as="p">
              When a visual language of our mobile apps has been established, we’ve transitioned the website to use it as well, considering how aged it looked in comparison.
            </Text>
          </Box>
        </Grid>
      </Box>
    </Box>
  </Layout>